import { TBuildingMap } from "../types/building";
import { createCtx } from "./general"
import { SetActionPayload, SetActionReducer } from "./hooks/useSetActions";
export const GLOBAL_SET_ACTION = "GLOBAL_SET_ACTION";

export enum GlobalStateKeysEnum {
    BUILDINGS = "BUILDINGS",
    ISLOADING = "ISLOADING"
}

type TState = {
    [GlobalStateKeysEnum.BUILDINGS]: TBuildingMap,
    [GlobalStateKeysEnum.ISLOADING]: boolean
}
// usage
const initialState: TState = {
    [GlobalStateKeysEnum.BUILDINGS]: {},  //reload everytime 
    [GlobalStateKeysEnum.ISLOADING]: false,
}
type AppState = TState;
type Action =
    | { type: typeof GLOBAL_SET_ACTION, payload: SetActionPayload }

function reducer(state: AppState, action: Action): AppState {
    switch (action.type) {
        case GLOBAL_SET_ACTION:
            return SetActionReducer(action,state)
        default:
            return state;
    }
}

export const [globalContext, GlobalProvider] = createCtx(reducer, initialState);