export enum UrlsEnum {
    INDEX='/',
    BOOK_CONFIRM="/book-confirm",
    ROOMS_LIST="/rooms",
    BUILDINGS_LIST="/buildings",
    POWER_BI="/embed"

}

export const routesGoBackMap:{[key:string]:string} = {
    [UrlsEnum.BOOK_CONFIRM]: UrlsEnum.INDEX,
    [UrlsEnum.ROOMS_LIST]: UrlsEnum.INDEX,
    [UrlsEnum.BUILDINGS_LIST]: UrlsEnum.INDEX,
}