import { createTheme } from '@mui/material/styles';
export const mainGreen = 'rgb(37,137,0)';
export const mainDarkGreen = 'rgb(25, 95, 0)' //duplicated from theme.tsx
export const mainRed = 'rgb(137,37,0)';
export const theme = createTheme({
  palette: {
    primary: {
      main: mainGreen,
    },
    secondary: {
      main: mainRed,
    },
  },
});