import React from 'react';
import { FlexCol } from '../../Flex/FlexCol';
import { BuildingSelector } from '../BuildingSelector/BuildingSelector';
import { RoomSelector } from '../RoomSelector/RoomSelector';
import { SectionSeparator } from '../../SectionSeparator/SectionSeparator';
const CurrentSelections: React.FC = () => {
    return <FlexCol>
        <BuildingSelector />
        <div className="mt-1" />
        <RoomSelector />
        <div className="mb-1" />
        <SectionSeparator />
    </FlexCol>

}
export { CurrentSelections }