import React from "react";
import { NAVIGATION_SET_ACTION } from "../navigationStore";
import { ERRORS_SET_ACTION } from "../errorsStore";
import { GLOBAL_SET_ACTION } from "../globalStore";
export type SetActionType = typeof ERRORS_SET_ACTION | typeof GLOBAL_SET_ACTION | typeof NAVIGATION_SET_ACTION
export type SetActionPayload = { key: SetActionType, data: any }
type Action = { type: SetActionType, payload: SetActionPayload }
export const SetActionReducer = (action: Action, state: any) => {
    return { ...state, [action.payload.key as unknown as string]: action.payload.data }
}
export const useSetActions = (actionType: SetActionType, state: any, dispatch: React.Dispatch<any>) => {
    type TState_key = keyof typeof state;
    const setActions: { [key: TState_key]: React.Dispatch<any> } = React.useMemo(() => Object.fromEntries(Object.entries(state).map(([key, value]: [TState_key, any]) => (
        [[key], (payload: typeof value) => dispatch({ type: actionType, payload: { key: [key], data: payload } })]
    ))), [state, dispatch])
    return {
        setActions
    }
}