import { IUserInfo } from "../types/user"
import { StoreKey } from "./autosave"
import { createCtx } from "./general"
const STORE_KEY = StoreKey.USER;
export enum UsereStateKeysEnum {
    USERINFO = "USERINFO",
}
type TState = {
    userInfo: IUserInfo,
}

// usage
const initialState: TState = {
    userInfo: {
    },
}
type AppState = TState;
type Action =
    | { type: 'SET_USER_INFO', payload: IUserInfo }
    | { type: 'SET_ACCESS_TOKEN', payload: string }

function reducer(state: AppState, action: Action): AppState {
    switch (action.type) {
        case 'SET_ACCESS_TOKEN':
            return { ...state, userInfo: { ...state.userInfo, accessToken: action.payload } }
        case 'SET_USER_INFO':
            return { ...state, userInfo: action.payload }
        default:
            return state;
    }
}

export const [userContext, UserProvider] = createCtx(reducer, initialState);