import React from 'react';
import { roomsMockData } from './mockedData';
import { RoomCard } from './RoomCard/RoomCard';
import { FlexCol } from '../../components/Flex/FlexCol';
import { Spacer } from '../../components/Spacer/Spacer';
import { FlexRow } from '../../components/Flex/FlexRow';
import { NumberOfPartecipantsSelector } from '../../components/NumberOfPartecipantsSelector/NumberOfPartecipantsSelector';
import { useQueryValues } from '../../hooks/useQueryValues';
import { useGlobalContext } from '../../stores/hooks/useGlobalContext';
import { TextField } from '@mui/material';
import { IRoom } from '../../types/rooms';
import { CurrentSelections } from '../../components/SelectorContainer/CurrentSelections/CurrentSelections';
import { useNavigationContext } from '../../stores/hooks/useNavigationContext';
const RoomsList: React.FC = () => {
    const {
        queryValues,
    } = useQueryValues();
    const {
        numberOfPartecipants,
        setNumberOfPartecipants
    } = useNavigationContext();
    const {
        globalBuildings
    } = useGlobalContext();
    const currentRoom = React.useMemo(() => roomsMockData.find((r) => r.name === queryValues.name), [queryValues])
    const [searchRoom, setSearchRoom] = React.useState('');
    const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setSearchRoom(e.target.value)
    }
    const buildingFilter = (r: IRoom) => r.cri === queryValues.cri;
    const filterRooms = React.useCallback((r: IRoom) => {
        const fixed_filter = (room: IRoom) => room.name !== queryValues.name && room.numberOfPeople === numberOfPartecipants.toString();
        const isStringIncluded = (s: string) => s.toLowerCase().includes(searchRoom.toLowerCase())
        return searchRoom ? fixed_filter(r) && (isStringIncluded(r.fullName) || isStringIncluded(r.description) || isStringIncluded(r.code)) : fixed_filter(r);
    }, [roomsMockData, searchRoom, queryValues, numberOfPartecipants])
    const buildingRooms = React.useMemo(() => roomsMockData.filter(buildingFilter), [queryValues, numberOfPartecipants])
    const equalRooms = React.useMemo(() => buildingRooms.filter(filterRooms), [queryValues, numberOfPartecipants])
    React.useEffect(() => {
        if (currentRoom && numberOfPartecipants === 0) {
            setNumberOfPartecipants(parseInt(currentRoom.numberOfPeople))
        }
    }, [])
    const listRef = React.useRef<HTMLInputElement>(null);

    return <div className="p-2 py-0" style={{ width: '100%', maxHeight: '100%', overflow: 'auto' }}>
        <div ref={listRef} />
        <CurrentSelections />
        <div className="my-1" />
        <FlexCol>
            {currentRoom && (searchRoom || equalRooms.length > 0) && <p className="text-start text-2xl  text-main-green">Altre sale</p>}
            {(searchRoom || equalRooms.length > 0)
                ? <>
                    <FlexRow justifyContent='space-between' alignItems='center' className="w-[100%]">
                        <TextField
                            size="small"
                            variant="outlined"
                            color="primary"
                            placeholder='Cerca...'
                            value={searchRoom}
                            onChange={handleSearch}
                        />
                        <NumberOfPartecipantsSelector rooms={equalRooms} />
                    </FlexRow>
                    <div className="my-1" />
                    {equalRooms.length > 0
                        ?
                        <>{equalRooms.map((room) => <div className="w-[100%]" key={room.name}>< RoomCard room={room} /><div className="mt-2" /></div>)}</>
                        :
                        <p className="text-xl w-[100%] text-center">
                            Non ci sono altre stanze con {queryValues.numberOfPeople} posti
                        </p>
                    }
                </>
                :
                <p className="text-xl w-[100%] text-center">{buildingRooms.length > 0 ? "Nessun'altra" : "Nessuna"} stanza configurata {globalBuildings[queryValues.cri] ? `per ${globalBuildings[queryValues.cri].BuildingName}` : ''}</p>
            }
        </FlexCol>
    </div>
}
export { RoomsList }