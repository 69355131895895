import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import axios, { AxiosError } from "axios";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import { apiLoginRequest } from "../../authConfig";
import { useUserContext } from "./useUserContext";

export const useUserStore = () => {
    //only call this one time, from a permanent component
    const { userState, dispatch, setUserInfo, setAccessToken } = useUserContext();

    const { instance, accounts, inProgress } = useMsal();
    const [interceptorSetted, setInterceptorsSetted] = React.useState(false);
    const isAuthenticated = useIsAuthenticated();
    const setAxiosInterceptors = (token: string) => {
        axios.interceptors.request.use((config) => {
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }

            return config;
        },
            (error) => Promise.reject(error)
        )
        axios.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        }, function (error:AxiosError) {
            // Reject promise if usual error
            if (error.response && error.response.status !== 401) {
                return Promise.reject(error);
            }
            instance.loginRedirect(apiLoginRequest)
                .then(() =>
                    instance.acquireTokenSilent(apiLoginRequest).then((response) => {
                        setAccessToken(response.accessToken)
                        const payload = response.account ? { ...userState.userInfo, accessToken: response.accessToken, account: response.account } : { ...userState.userInfo, accessToken: response.accessToken };
                        setUserInfo(payload);
                    }))
                .catch(e => {
                    console.log(e)
                });

        });
        setInterceptorsSetted(true);
    }
    const tryGetToken = async () => {
        if (inProgress === InteractionStatus.None && isAuthenticated && accounts.length > 0) {
            if (!userState.userInfo.accessToken) {
                instance.setActiveAccount(accounts[0])
                instance.acquireTokenSilent(apiLoginRequest).then((response) => {
                    setAxiosInterceptors(response.accessToken);
                    const payload = response.account ? { ...userState.userInfo, accessToken: response.accessToken, account: response.account } : { ...userState.userInfo, accessToken: response.accessToken };
                    setUserInfo(payload);
                })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    }
    React.useEffect(() => {
        if (!userState.userInfo.accessToken) {
            tryGetToken()
        } else {
            if (!interceptorSetted) {
                setAxiosInterceptors(userState.userInfo.accessToken)
            }
        }
    }, [isAuthenticated, inProgress, userState.userInfo.accessToken])
    return {
        userState: userState,
        userInfo: userState.userInfo,
        accessToken: userState.userInfo.accessToken,
        account: userState.userInfo.account,
        dispatch,
        setUserInfo,
        setAccessToken,
    }
}