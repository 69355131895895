import { createCtx } from "./general"
import { SetActionType, SetActionPayload, SetActionReducer } from "./hooks/useSetActions";
export const ERRORS_SET_ACTION = "ERRORS_SET_ACTION"

export enum ErrorsStateKeysEnum {
    BUILDINGS_ERROR = "BUILDINGS_ERROR",
    NO_BUILDING_SELECTED = "NO_BUILDING_SELECTED",
    ROOM_NOT_FOUND = "ROOM_NOT_FOUND"
}
export const contextName = 'ERRORS';

type TState = {
    [ErrorsStateKeysEnum.BUILDINGS_ERROR]: string,
    [ErrorsStateKeysEnum.NO_BUILDING_SELECTED]: string,
    [ErrorsStateKeysEnum.ROOM_NOT_FOUND]: string,
}
// usage
const initialState: TState = {
    [ErrorsStateKeysEnum.BUILDINGS_ERROR]: '',
    [ErrorsStateKeysEnum.NO_BUILDING_SELECTED]: '',
    [ErrorsStateKeysEnum.ROOM_NOT_FOUND]: '',
}
type AppState = TState;
type Action =
    | { type: typeof ERRORS_SET_ACTION, payload: SetActionPayload }

function reducer(state: AppState, action: Action): AppState {
    switch (action.type) {
        case ERRORS_SET_ACTION:
            return SetActionReducer(action,state)
        default:
            return state;
    }
}

export const [errorsContext, ErrorsProvider] = createCtx(reducer, initialState);