import React from "react";
import { useTypedContext } from "./useContext";
import { StoreKey, saveState } from "../autosave";
import { NAVIGATION_SET_ACTION, NavigationStateKeysEnum, navigationContext } from "../navigationStore";
import { IPanelOptions } from "../../types/panels";
const STORE_KEY = StoreKey.NAVIGATION;
export const useNavigationContext = () => {
    const {
        state: navigationState,
        dispatch: navigationDispatch,
        setActions
    } = useTypedContext({ actionType: NAVIGATION_SET_ACTION, context: navigationContext, keys_enum: NavigationStateKeysEnum })
    const setNumberOfPartecipants = React.useCallback((n: number) => setActions[NavigationStateKeysEnum.NUMBER_OF_PARTECIPANTS](n), [setActions, NavigationStateKeysEnum])
    const setDefaultNavigationType = React.useCallback(() => setActions[NavigationStateKeysEnum.NAVIGATION_TYPE]("default"), [navigationState,setActions, NavigationStateKeysEnum])
    const setPanelNavigationType = React.useCallback(() => setActions[NavigationStateKeysEnum.NAVIGATION_TYPE]("panel"), [navigationState,setActions, NavigationStateKeysEnum])
    React.useEffect(()=>{
        if(navigationState[NavigationStateKeysEnum.PANEL].isOpen){
            setPanelNavigationType();
        }else{
            setDefaultNavigationType();
        }
    },[navigationState[NavigationStateKeysEnum.PANEL].isOpen])
    const setPanelOptions = React.useCallback((panelOptions: IPanelOptions) => {
        navigationDispatch({
            type: "SET_PANEL_OPTIONS",
            panelOptions
        })
    }, [navigationState, NavigationStateKeysEnum])
    const setPanelIsOpen = React.useCallback((b:boolean)=>setPanelOptions({...navigationState[NavigationStateKeysEnum.PANEL],isOpen:b}),[navigationState,setPanelOptions])
    const setPanelChildrens = React.useCallback((panelChildrens:React.ReactNode|null)=>setPanelOptions({...navigationState[NavigationStateKeysEnum.PANEL],panelChildrens}),[navigationState,setPanelOptions])
    return {
        navigationState,
        navigationDispatch,
        navigationType: navigationState[NavigationStateKeysEnum.NAVIGATION_TYPE],
        numberOfPartecipants: navigationState[NavigationStateKeysEnum.NUMBER_OF_PARTECIPANTS],
        panel: navigationState[NavigationStateKeysEnum.PANEL],
        setNumberOfPartecipants,
        setPanelOptions,
        setPanelIsOpen,
        setPanelChildrens,
    }
}