import React from "react";
import { FlexCol } from "../../components/Flex/FlexCol";
import useWindowSize from "../../hooks/useWindowSize";
import { Topbar, topbarHeight } from "../../components/Topbar/Topbar";
import { Bottombar } from "../../components/Bottombar/Bottombar";
import { BrowserView, isMobile } from "react-device-detect";
import { useTitle } from "../../hooks/useTitles";
import { useUserStore } from "../../stores/hooks/useUserStore";
import { useGlobalContext } from "../../stores/hooks/useGlobalContext";
import { getBuildings } from "../../services/building";
import { IBuildingInfos } from "../../types/building";
import { useQueryValues } from "../../hooks/useQueryValues";
import { useErrorsContext } from "../../stores/hooks/useErrorsContext";
import { DisposablePanel } from "../DisposablePanel/DisposablePanel";
import { SiGoogleclassroom } from "react-icons/si";
import { Button, IconButton } from "@mui/material";
import { UrlsEnum } from "../../types/urls";
import { useLocation, useNavigate } from "react-router";
type Props = {
  children?: React.ReactNode;
};
export const buildingsMap: { [key: string]: string } = {
  IT015146000829: "Torre Gioia 22",
};
const MobileWrapper: React.FC<Props> = ({ children }) => {
  const { queryValues } = useQueryValues();
  const BUILDING_NOT_FOUND_ERROR = `Edificio ${queryValues.cri} non trovato`;
  const BUILDING_MOCK = {
    BuildingName: buildingsMap[queryValues.cri],
    CRImmobile: "",
    Polo: "",
    Address: "",
  };
  const { setBuildingsError } = useErrorsContext();
  const location = useLocation();
  useTitle();
  const { userInfo } = useUserStore();
  const { globalBuildings, setBuildings, setLoading } = useGlobalContext();
  const currentBuilding = React.useMemo(
    () => globalBuildings[queryValues.cri],
    [globalBuildings, queryValues.cri]
  );
  const scrollInto = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    // scrollInto.current?.scrollIntoView(); doesnt work well
  }, []);
  React.useEffect(() => {
    if (userInfo.accessToken && !currentBuilding) {
      setLoading(true);
      const loadBuildings = async () => {
        getBuildings()
          .then((res: IBuildingInfos[]) => {
            const buildingsMocks = Object.fromEntries(
              Object.entries(buildingsMap).map(([cri, name]) => [
                cri,
                { ...BUILDING_MOCK, BuildingName: name, CRImmobile: cri },
              ])
            );
            const buildings = {
              ...Object.fromEntries(res.map((b) => [b.CRImmobile, b])),
              ...buildingsMocks,
            };

            setBuildings(buildings);
          })
          .catch((err: any) => {
            setBuildingsError(BUILDING_NOT_FOUND_ERROR);
          })
          .finally(() => setLoading(false));
      };
      loadBuildings();
    }
  }, [queryValues.cri, userInfo.accessToken]);
  const { width, height } = useWindowSize();
  const desktopWidth = width && width < 400 ? width : 400;
  const desktopHeight = height && height < 600 ? height : 600;
  const boxStyles: React.CSSProperties = React.useMemo(
    () => ({
      paddingTop: topbarHeight + 5,
      paddingBottom: topbarHeight + 5,
      width: isMobile ? "100vw" : desktopWidth,
      height: isMobile ? "100vh" : desktopHeight,
      // backgroundColor: 'snow',
      border: "1px solid lightgrey",
      borderRadius: isMobile ? 0 : 10,
    }),
    [isMobile, desktopHeight, desktopWidth]
  );
  const navigate = useNavigate();
  const navigateToReportTest = () => {
    navigate(
      `${UrlsEnum.POWER_BI}?report-id=2658b7be-6da7-4b1d-89ac-e354a923c98a&workspace-id=6eae3de6-456b-4a56-9849-803774b49128`
    );
  };
  if (location.pathname === UrlsEnum.POWER_BI) {
    return <div className="bg-slate-600 h-[100vh] w-[100vw]">{children}</div>;
  }
  return (
    <FlexCol
        className="!bg-slate-100"
        style={{ position: "relative", ...boxStyles }}
        justifyContent="start"
        alignItems="center"
      >
        <div ref={scrollInto} />
        <BrowserView>
          <DisposablePanel
            renderIcon={(
              onClick,
              btnStyles,
              btnClassNames,
              iconStyles,
              iconClassNames
            ) => (
              <IconButton
                onClick={onClick}
                style={btnStyles}
                className={btnClassNames}
              >
                <SiGoogleclassroom
                  style={iconStyles}
                  className={iconClassNames}
                />
              </IconButton>
            )}
          />
        </BrowserView>
        <Topbar />
        <FlexCol
          justifyContent="start"
          alignItems="center"
          style={{ width: "100%", height: "100%" }}
        >
          {children}
        </FlexCol>
        <Bottombar />
      </FlexCol>
  );
};
export { MobileWrapper };
