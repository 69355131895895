import React from 'react';
import { useGlobalContext } from '../../stores/hooks/useGlobalContext';
import { IBuildingInfos } from '../../types/building';
import { BuildingCard } from './BuildingCard/BuildingCard';
import { TextField } from '@mui/material';
import { useLocation } from 'react-router';
import { UrlsEnum } from '../../types/urls';
import { useQueryValues } from '../../hooks/useQueryValues';
import { SectionSeparator } from '../../components/SectionSeparator/SectionSeparator';
import { BuildingSelector } from '../../components/SelectorContainer/BuildingSelector/BuildingSelector';
import { CurrentSelections } from '../../components/SelectorContainer/CurrentSelections/CurrentSelections';

const BuildingsList: React.FC = () => {
    const {
        globalBuildings
    } = useGlobalContext();
    const {
        queryValues
    } = useQueryValues();
    const location = useLocation();
    const [searchBuilding, setSearchBuilding] = React.useState('');
    const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setSearchBuilding(e.target.value)
    }
    const filterBuildings = React.useCallback((b: IBuildingInfos) => {
        return searchBuilding ? b.BuildingName.toLowerCase().includes(searchBuilding.toLowerCase()) : true;
    }, [globalBuildings, searchBuilding])
    const buildings = React.useMemo(() => (Object.values(globalBuildings) as IBuildingInfos[]).filter(filterBuildings), [globalBuildings, searchBuilding])
    const listRef = React.useRef<HTMLInputElement>(null);
    return <div className="rounded p-2 py-0 m-0 w-[100%] overflow-auto">
        <div ref={listRef} />
        <CurrentSelections />
        <div className="mt-1" />
        <TextField
            size="small"
            variant="outlined"
            color="primary"
            placeholder='Cerca...'
            value={searchBuilding}
            onChange={handleSearch}
        />
        <div className="mb-1" />
        {buildings.filter(b => b.CRImmobile !== queryValues.cri).map((building: IBuildingInfos) => <BuildingCard key={building.CRImmobile} listRef={listRef} building={building} />)}
    </div>
}
export { BuildingsList }