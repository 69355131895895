import React from 'react';
import { IBuildingInfos } from '../../../types/building';
import { Paper } from '@mui/material';
import '../../../utility/commonStyles.css';
import { useQueryValues } from '../../../hooks/useQueryValues';
import { useLocation, useNavigate } from 'react-router';
import { UrlsEnum } from '../../../types/urls';
import { roomsMockData } from '../../RoomsList/mockedData';
import { IRoom } from '../../../types/rooms';
import { useNavigationContext } from '../../../stores/hooks/useNavigationContext';
import { RoomsList } from '../../RoomsList/RoomsList';
type Props = {
    building: IBuildingInfos
    current?: boolean
    customOnClick?: (e: React.MouseEvent<HTMLElement>) => void
    listRef?: React.RefObject<HTMLInputElement>
}
const BuildingCard: React.FC<Props> = ({ current, building, customOnClick,listRef }) => {
    const {
        queryValues,
        encodeValues
    } = useQueryValues();
    const navigate = useNavigate();
    const {
        navigationType,
        setPanelOptions,
        panel,
    } = useNavigationContext();
    const location = useLocation();
    const handleBuildingClick = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
        if (customOnClick) {
            return customOnClick(e);
        }
        e.stopPropagation();
        const rooms = roomsMockData.filter((r) => r.cri === building.CRImmobile);
        const emptyRoom: IRoom = {
            name: '',
            fullName: '',
            numberOfPeople: '',
            floorNumber: '',
            cri: '',
            code: '',
            description: ''
        }
        const newRoom = rooms.length > 0 ? rooms[0] : emptyRoom;
        const newQueryValues = { ...queryValues, ...newRoom, cri: building.CRImmobile }
        navigate(encodeValues(UrlsEnum.INDEX, ['cri', 'code', 'description', 'name', 'numberOfPeople', 'floorNumber'], newQueryValues, true))
        if(navigationType === "panel" && roomsMockData.filter((r)=>r.cri === building.CRImmobile).length > 0){
            setPanelOptions({...panel,panelChildrens:<RoomsList/>,title:"Sale"})
        }
        if(listRef){
            listRef.current?.scrollIntoView();
        }
        // else {
        //     navigate(encodeValues(routesGoBackMap[location.pathname] ?? -1, undefined, { ...queryValues, cri: building.CRImmobile }))
        // }
    }, [building])
    const classNames = React.useMemo(() => "min-h-[40px] w-[100%] transition-all-short hover:!bg-slate-100 !text-black cursor-pointer px-2", [current, building])
    const currentClassNames = React.useMemo(() => `${classNames} !bg-slate-400 hover:!bg-slate-400 text-center text-2xl`, [classNames])
    const defaultClassNames = React.useMemo(() => `${classNames} my-1 !bg-slate-300 hover:!bg-slate-500 text-left text-xl hover:!text-white`, [classNames])
    return <Paper className={current ? currentClassNames : defaultClassNames} onClick={handleBuildingClick}>
        <p>{building.BuildingName}</p>
    </Paper>
}
export { BuildingCard }