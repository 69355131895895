import { RoomsList } from "../pages/RoomsList/RoomsList";
import { IPanelOptions } from "../types/panels";
import { createCtx } from "./general"
import { SetActionPayload, SetActionReducer } from "./hooks/useSetActions";

export const NAVIGATION_SET_ACTION = 'NAVIGATION_SET_ACTION';

export enum NavigationStateKeysEnum {
    NUMBER_OF_PARTECIPANTS = "NUMBER_OF_PARTECIPANTS",
    NAVIGATION_TYPE = "NAVIGATION_TYPE",
    PANEL = "PANEL",
}
type NavigationTypes = "panel" | "default"
type TState = {
    [NavigationStateKeysEnum.NUMBER_OF_PARTECIPANTS]: number,
    [NavigationStateKeysEnum.NAVIGATION_TYPE]: NavigationTypes,
    [NavigationStateKeysEnum.PANEL]: IPanelOptions,
}
// usage
const initialState: TState = {
    [NavigationStateKeysEnum.NAVIGATION_TYPE]: "default",
    [NavigationStateKeysEnum.NUMBER_OF_PARTECIPANTS]: 0,
    [NavigationStateKeysEnum.PANEL]: {
        isOpen:false,
        title:"Sale",
        panelChildrens:<RoomsList/>,
        panelWidth:"xl",
        panelPosition:"right",
    },
}
type AppState = TState;
type Action =
    | { type: typeof NAVIGATION_SET_ACTION, payload: SetActionPayload }
    | { type: "SET_PANEL_OPTIONS", panelOptions: IPanelOptions }

function reducer(state: AppState, action: Action): AppState {
    switch (action.type) {
        case NAVIGATION_SET_ACTION:
            return SetActionReducer(action, state)
        case "SET_PANEL_OPTIONS":
            return {...state,[NavigationStateKeysEnum.PANEL]:action.panelOptions}
        default:
            return state;
    }
}

export const [navigationContext, NavigationProvider] = createCtx(reducer, initialState);