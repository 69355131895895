import React from "react";
import { ERRORS_SET_ACTION, ErrorsStateKeysEnum, errorsContext } from "../errorsStore";
import { useTypedContext } from "./useContext";
export const useErrorsContext = () => {
    const {
        state: errorsState,
        dispatch: errorsDispatch,
        setActions
    } = useTypedContext({actionType:ERRORS_SET_ACTION, context:errorsContext,keys_enum:ErrorsStateKeysEnum})
    const setBuildingsError =  React.useCallback((s:string)=>setActions[ErrorsStateKeysEnum.BUILDINGS_ERROR](s ?? 'Edificio non trovato'),[setActions,ErrorsStateKeysEnum])
    const setRoomNotFoundError = React.useCallback((s?:string)=>setActions[ErrorsStateKeysEnum.ROOM_NOT_FOUND](s ?? 'Stanza non trovata'),[setActions,ErrorsStateKeysEnum])
    const setNoBuildingSelectedError = React.useCallback((s?:string)=>setActions[ErrorsStateKeysEnum.NO_BUILDING_SELECTED](s ?? 'Seleziona un edificio'),[setActions,ErrorsStateKeysEnum])
    const clearError = React.useCallback((key:ErrorsStateKeysEnum)=>setActions[key](''),[setActions,ErrorsStateKeysEnum])
    return {
        errorsState,
        buildingsError:errorsState[ErrorsStateKeysEnum.BUILDINGS_ERROR],
        roomNotFoundError:errorsState[ErrorsStateKeysEnum.ROOM_NOT_FOUND],
        noBuildingSelectedError:errorsState[ErrorsStateKeysEnum.NO_BUILDING_SELECTED],
        errorsDispatch,
        setBuildingsError,
        setNoBuildingSelectedError,
        clearError,
        setRoomNotFoundError,
    }
}