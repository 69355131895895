import { PowerBIEmbed } from "powerbi-client-react";
import { models, Report, Embed, service } from "powerbi-client";
import { useCallback, useEffect, useState } from "react";
import React from "react";
import useWindowSize from "../../hooks/useWindowSize";
import { useQuery } from "../../hooks/useQuery";
import { getPowerBiReport } from "../../services/powerBI";
import { CircularProgress } from "@mui/material";
import { useUserContext } from "../../stores/hooks/useUserContext";

export interface PBIConfig {}
export const PowerBIWrapper = (props: PBIConfig) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { accessToken } = useUserContext();
  const { height } = useWindowSize();
  // Report config useState hook
  // Values for properties like embedUrl, accessToken and settings will be set on click of buttons below
  const [reportConfig, setReportConfig] =
    useState<models.IReportEmbedConfiguration>({
      type: "report",
      embedUrl: undefined,
      tokenType: models.TokenType.Embed,
      accessToken: undefined,
      settings: {
        bars: {
          actionBar: {
            visible: true,
          },
        },
        panes: {
          pageNavigation: {
            visible: true,
            position: models.PageNavigationPosition.Left,
          },
        },
        personalBookmarksEnabled: true,
      },
    });
  const query = useQuery();
  const workspaceId = query.get("workspace-id");
  const reportId = query.get("report-id");
  // Fetch sample report's config (eg. embedUrl and AccessToken) for embedding
  const getReportConfig = useCallback(async () => {
    if (!accessToken) {
      console.log("No Access Token");
      return;
    }
    if (!workspaceId || !reportId) {
      console.error(`workspaceId or reportId undefined`, workspaceId, reportId);
      return;
    }
    setIsLoading(true);
    // Fetch sample report's embed config
    getPowerBiReport(workspaceId, reportId)
      .then((res) => {
        if (res.EmbedReport.length > 0) {
          setReportConfig({
            ...reportConfig,
            embedUrl: res.EmbedReport[0].EmbedUrl,
            accessToken: res.EmbedToken.token,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
    // Set the fetched embedUrl and embedToken in the report config
  }, [query, accessToken]); // se cambia props renderizza ancora

  useEffect(() => {
    getReportConfig();
  }, [getReportConfig]);
  // Map of event handlers to be applied to the embedding report
  const eventHandlersMap = React.useMemo(
    () =>
      new Map([
        [
          "loaded",
          function () {
            console.log("Report has loaded");
          },
        ],
        [
          "rendered",
          function () {
            console.log("Report has rendered");
          },
        ],
        [
          "error",
          function (event?: service.ICustomEvent<any>) {
            if (event) {
              console.error(event.detail);
            }
          },
        ],
      ]),
    []
  );
  const [_, setReport] = React.useState<Report>();
  const _PowerBIEmbed = React.useMemo(
    () => (
      <PowerBIEmbed
        embedConfig={reportConfig}
        eventHandlers={eventHandlersMap}
        cssClassName={`h-[100%]`}
        getEmbeddedComponent={(embedObject: Embed) => {
          setReport(embedObject as Report);
        }}
      />
    ),
    [eventHandlersMap, reportConfig]
  );
  const topbarHeight = 0;
  return (
    <div
      className="flex flex-col"
      style={{
        height: '100vh',
        width:'100vw'
      }}
    >
      {isLoading ? <div className="flex p-2">
         <CircularProgress style={{ marginLeft: 25 }} />
      </div>:
      _PowerBIEmbed}
    </div>
  );
};
