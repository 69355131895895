import React from 'react';
import { useQueryValues } from '../../../hooks/useQueryValues';
import { useLocation, useNavigate } from 'react-router';
import { UrlsEnum } from '../../../types/urls';
import '../../../utility/commonStyles.css';
import { SelectorContainer } from '../SelectorContainer';
import { useErrorsContext } from '../../../stores/hooks/useErrorsContext';
import { ErrorsStateKeysEnum } from '../../../stores/errorsStore';
import { roomsMockData } from '../../../pages/RoomsList/mockedData';
import { SelectorLoadingIcon } from '../SelectorLoadingIcon/SelectorLoadingIcon';
import { useGlobalContext } from '../../../stores/hooks/useGlobalContext';
import { SelectorEditIcon } from '../SelectorEditIcon/SelectorEditIcon';
import { useNavigationContext } from '../../../stores/hooks/useNavigationContext';
import { RoomsList } from '../../../pages/RoomsList/RoomsList';
const RoomSelector: React.FC = () => {
    const {
        queryValues,
        encodeValues
    } = useQueryValues();
    const {
        navigationType,
        setPanelOptions,
        panel,
    } = useNavigationContext();
    const {
        globalLoading
    } = useGlobalContext();
    const {
        roomNotFoundError,
        setRoomNotFoundError,
        clearError
    } = useErrorsContext();
    const location = useLocation();
    const currentRoom = React.useMemo(() => roomsMockData.find(r => r.cri === queryValues.cri && r.name === queryValues.name), [queryValues.name, queryValues.cri])
    const navigate = useNavigate();
    const handleRoomsClick = React.useCallback(() => {
        const url = location.pathname === UrlsEnum.ROOMS_LIST && currentRoom ? UrlsEnum.INDEX : UrlsEnum.ROOMS_LIST;
        if (navigationType === "default") {
            navigate(encodeValues(url, ['name', 'cri', 'numberOfPeople', 'code', 'floorNumber', 'description'], currentRoom))
        }else{
            setPanelOptions({...panel,panelChildrens:<RoomsList/>,title:"Sale"})
        }
        // navigate(encodeValues(UrlsEnum.ROOMS_LIST, ['name', 'cri', 'numberOfPeople', 'code', 'floorNumber', 'description']))
    }, [queryValues])
    React.useEffect(() => {
        if (queryValues.name && !currentRoom) {
            setRoomNotFoundError(`Sala ${queryValues.name} non trovata`);
        } else {
            clearError(ErrorsStateKeysEnum.ROOM_NOT_FOUND)
        }
    }, [currentRoom])
    const max_priority_error = React.useMemo(() => {
        if (roomNotFoundError) {
            return roomNotFoundError
        }
        return 'Seleziona una stanza';
    }, [roomNotFoundError])
    return <SelectorContainer onClick={handleRoomsClick}>
        {currentRoom
            ?
            <p
                className="text-xl font-bold text-center w-[100%]"
                style={{ margin: 2 }}>
                {currentRoom.name} ({currentRoom.numberOfPeople} posti)
            </p>
            :
            <p className="text-xl font-bold text-slate-500 w-[100%] text-center">{max_priority_error}</p>
        }
        {globalLoading
            ? <SelectorLoadingIcon />
            : <SelectorEditIcon />}
    </SelectorContainer>
}
export { RoomSelector }