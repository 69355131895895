import React from "react";
import { IUserInfo } from "../../types/user";
import { userContext } from "../userStore";
import { StoreKey, saveState } from "../autosave";
const STORE_KEY = StoreKey.USER;
export const useUserContext = () => {
    const { state, dispatch } = React.useContext(userContext);
    const setUserInfo = (payload: IUserInfo) => dispatch({ type: "SET_USER_INFO", payload })
    const setAccessToken = (payload: string) => dispatch({ type: "SET_ACCESS_TOKEN", payload })
    React.useEffect(()=>{
        saveState(STORE_KEY,state)
    },[state])
    return {
        userState: state,
        userInfo: state.userInfo,
        accessToken: state.userInfo.accessToken,
        account: state.userInfo.account,
        dispatch,
        setUserInfo,
        setAccessToken,
    }
}