import React from 'react';
interface Props {
    children?: React.ReactNode,
    justifyContent?: 'start' | 'center' | 'end' | string
    alignItems?: 'start' | 'center' | 'end' | string
    style?: React.CSSProperties
    className?: string
    onClick?:(e:any)=>void
}
const FlexRow: React.FC<Props> = ({onClick, children, style, className, justifyContent,alignItems }) => {
    return <div onClick={onClick} style={
        {
            display: 'flex',
            flexDirection: 'row', 
            justifyContent:justifyContent ? justifyContent : 'start', 
            alignItems:alignItems?alignItems : 'start',
            ...style
        }} className={className ?? ''}>
        {children}
    </div>
}
export { FlexRow }