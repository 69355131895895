import axios from "axios";
import { IBuildingInfos } from "../types/building";

export async function getBuildings() {
    return axios.get(`${process.env.REACT_APP_SERVER_URL_ODATA}/building`)
        .then((res) => res.data.value as IBuildingInfos[])
}

export async function getBuildingInfo(cri: string) {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.get(`${process.env.REACT_APP_SERVER_URL}/Building/Byid/${cri}`, { headers }).then((res) => res.data)
}