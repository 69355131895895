import React from 'react';
import { useGlobalContext } from '../../../stores/hooks/useGlobalContext';
import { useQueryValues } from '../../../hooks/useQueryValues';
import { useStateIsOpen } from '../../../utility/react-utilities';
import { useLocation, useNavigate } from 'react-router';
import { UrlsEnum } from '../../../types/urls';
import { useErrorsContext } from '../../../stores/hooks/useErrorsContext';
import { ErrorsStateKeysEnum } from '../../../stores/errorsStore';
import '../../../utility/commonStyles.css';
import { SelectorContainer } from '../SelectorContainer';
import { roomsMockData } from '../../../pages/RoomsList/mockedData';
import { SelectorLoadingIcon } from '../SelectorLoadingIcon/SelectorLoadingIcon';
import { SelectorEditIcon } from '../SelectorEditIcon/SelectorEditIcon';
import { BuildingsList } from '../../../pages/BuildingsList/BuildingsList';
import { useNavigationContext } from '../../../stores/hooks/useNavigationContext';
const BuildingSelector: React.FC = () => {
    const {
        globalLoading,
        globalBuildings
    } = useGlobalContext();
    const {
        noBuildingSelectedError,
        buildingsError,
        setNoBuildingSelectedError,
        clearError,
        setBuildingsError,
    } = useErrorsContext();
    const location = useLocation();
    const navigate = useNavigate();
    const {
        navigationType,
        panel,
        setPanelOptions,
    } = useNavigationContext();
    const {
        queryValues,
        encodeValues,
        replaceEncodedValue
    } = useQueryValues();
    const currentBuilding = React.useMemo(() => globalBuildings[queryValues.cri], [globalBuildings, queryValues.cri])
    const currentRoom = React.useMemo(() => roomsMockData.find(r => r.name === queryValues.name), [queryValues.name])
    const handleClick = () => {
        const url = location.pathname === UrlsEnum.BUILDINGS_LIST && currentBuilding ? UrlsEnum.INDEX : UrlsEnum.BUILDINGS_LIST;
        if (navigationType === "default") {
            navigate(encodeValues(url))
        }else{
            setPanelOptions({...panel,panelChildrens:<BuildingsList/>,title:"Edifici"})
        }
    }
    React.useEffect(() => {
        if (globalLoading || Object.values(globalBuildings).length === 0) {
            return
        }
        if (!queryValues.cri) {
            setNoBuildingSelectedError();
        } else {
            if (!currentBuilding) {
                setBuildingsError(`Edificio ${queryValues.cri} non trovato`)
            }
            if (currentRoom && currentBuilding && queryValues.cri !== currentRoom.cri) {
                navigate(replaceEncodedValue(['cri'], [currentRoom.cri]))
            }
            if (noBuildingSelectedError !== '') {
                clearError(ErrorsStateKeysEnum.NO_BUILDING_SELECTED);
            }
            if (buildingsError !== '') {
                clearError(ErrorsStateKeysEnum.BUILDINGS_ERROR)
            }
        }
    }, [queryValues.cri, queryValues.name, currentBuilding, globalLoading, globalBuildings])
    const max_priority_error = React.useMemo(() => {
        if (buildingsError) {
            return buildingsError
        }
        if (noBuildingSelectedError) {
            return noBuildingSelectedError
        }
        return '';
    }, [buildingsError, noBuildingSelectedError])
    return <SelectorContainer onClick={handleClick}>
        {currentBuilding ?
            <p
                className="text-xl font-bold text-center  w-[100%] "
                style={{ margin: 2 }}>
                {currentBuilding.BuildingName}
            </p>
            :
            <p className="text-xl font-bold text-slate-500 w-[100%] text-center">{max_priority_error}</p>
        }
        {globalLoading
            ? <SelectorLoadingIcon />
            : <SelectorEditIcon />}
    </SelectorContainer >

}
export { BuildingSelector }