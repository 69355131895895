import axios from "axios";
export type TReportResponse = {
    ReportId:string
    ReportName:string
    EmbedUrl:string
}
export interface IPowerBiResponse {
    EmbedReport:TReportResponse[]
    EmbedToken:{token:string}
}
export async function getPowerBiReport(workspaceId:string,reportId:string) {
    return axios.get(`${process.env.REACT_APP_SERVER_URL}/PowerBi?workspaceId=${workspaceId}&reportId=${reportId}`).then((res)=>res.data as IPowerBiResponse)
}