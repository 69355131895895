import React from 'react';
import { AiOutlineClose } from 'react-icons/ai'
import { FlexRow } from '../Flex/FlexRow';
import { IconButton } from '@mui/material';
import { SectionSeparator } from '../SectionSeparator/SectionSeparator';
import { useNavigationContext } from '../../stores/hooks/useNavigationContext';
type Props = {
    iconStyles?: React.CSSProperties,
    iconClassNames?: string,
    btnStyles?: React.CSSProperties
    btnClassNames?: string
    renderIcon: (onClick: (e: React.MouseEvent<HTMLElement>) => void, btnStyles: React.CSSProperties, btnClassNames: string, iconStyles: React.CSSProperties, iconClassNames: string) => React.ReactNode
}
const DisposablePanel: React.FC<Props> = ({ btnStyles, btnClassNames, iconStyles, iconClassNames, renderIcon }) => {
    const {
        panel,
        setPanelIsOpen,
    } = useNavigationContext();
    const {
        isOpen: panelIsOpen,
        title: panelTitle,
        panelWidth,
        panelChildrens,
        panelCustomWidth,
        panelPosition,
    } = panel;
    const getPanelWidth = React.useCallback(() => {
        if (panelWidth === "custom") return panelCustomWidth;
        switch (panelWidth) {
            case "s": return 300;
            case "m": return 400;
            case "l": return 500;
            case "xl": return 600;
            case "xxl": return 700;
        }
    }, [panelWidth, panelCustomWidth])
    const _panelWidth = getPanelWidth();
    const _leftPanelPosition = React.useMemo(() => panelIsOpen ? { left: 0, top: 0, height: '100vh' } : { left: -(_panelWidth), top: 50, height: 0 }, [panelIsOpen, _panelWidth])
    const _rightPanelPosition = React.useMemo(() => panelIsOpen ? { right: 0, top: 0, height: '100vh' } : { right: -(_panelWidth), top: 20, height: 0 }, [panelIsOpen, _panelWidth])
    const _panelPosition = panelPosition === "left" ? _leftPanelPosition : _rightPanelPosition
    const panelStyles: React.CSSProperties = React.useMemo(() => {
        return {
            position: 'fixed',
            // visibility: 'visible',
            // visibility: panelIsOpen ? 'visible' : 'hidden',
            backgroundColor: 'snow',
            padding: 8,
            // height: '100vh',
            width: _panelWidth,
            // opacity: panelIsOpen ? 1 : 0,
            transition: 'all 0.25s',
            // transition: "visibility 0.2s, opacity 0.3s linear",
            zIndex: 3,
            ..._panelPosition
        }
    }, [panelPosition, panelWidth, panelCustomWidth, panelIsOpen])
    const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setPanelIsOpen(true);
    }
    const handleClose = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setPanelIsOpen(false);
    }
    const defaultBtnStyles = React.useMemo(() => ({ right: 50, top: 50, fontSize: 30, width: 60, height: 60 }), [])
    const defaultBtnClassNames = React.useMemo(() => "!fixed !bg-transparent hover:!bg-slate-200 !text-main-green", []);
    const iconEl = React.useMemo(() =>
        renderIcon(handleOpen, btnStyles ?? defaultBtnStyles, btnClassNames ?? defaultBtnClassNames, iconStyles ?? {}, iconClassNames ?? ''),
        [btnStyles, btnClassNames, iconStyles, iconClassNames, defaultBtnStyles, defaultBtnClassNames, renderIcon])
    return <>
        <div className={`flex flex-col ${panelIsOpen ? 'visible' : 'invisible no-sidebar'}`} id="sidebar" style={panelStyles}>
            <FlexRow justifyContent='space-between' className="w-[100%] px-2" alignItems='center'>
                <p className="text-xl text-main-green font-bold">{panelTitle}</p>
                <IconButton id="sidebar-toggle" onClick={handleClose}><AiOutlineClose className="text-black" /></IconButton>
            </FlexRow>
            <div className="my-1" />
            <SectionSeparator />
            <div className="my-1" />
            {panelChildrens}
        </div>
        {panelIsOpen
            ?
            <div style={{ zIndex: 2, position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0,0,0,0.4)', cursor: 'pointer' }} onClick={handleClose} />
            :
            iconEl
        }
    </>
}
export { DisposablePanel }