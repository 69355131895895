import React from 'react';
import { IRoom } from '../../../types/rooms';
import { FlexRow } from '../../../components/Flex/FlexRow';
import { Paper } from '@mui/material'
import { FlexCol } from '../../../components/Flex/FlexCol';
import { useNavigate } from 'react-router';
import '../../../utility/commonStyles.css';
import { UrlsEnum } from '../../../types/urls';
import { useQueryValues } from '../../../hooks/useQueryValues';
import { useNavigationContext } from '../../../stores/hooks/useNavigationContext';
type Props = {
    room: IRoom
    current?: boolean
    listRef?: React.RefObject<HTMLInputElement>
}
const RoomCard: React.FC<Props> = ({ room, current,listRef }) => {
    const navigate = useNavigate();
    const {
        encodeValues
    } = useQueryValues();
    const {
        panel,
        setPanelIsOpen
    } = useNavigationContext();
    const handleRoomClick = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        navigate(encodeValues(UrlsEnum.INDEX, ['cri', 'code', 'description', 'name', 'numberOfPeople', 'floorNumber'], room))
        if(listRef){
            listRef.current?.scrollIntoView();
        }
        if(panel.isOpen){
            setPanelIsOpen(false);
        }
    }, [room])
    const classNames = React.useMemo(() => "w-[100%] h-[100px] cursor-pointer transition-all-short px-2", [current, room])
    const currentClassNames = React.useMemo(() => `${classNames} !bg-slate-300 hover:!bg-slate-300 text-black`, [classNames])
    const defaultClassNames = React.useMemo(() => `${classNames} !bg-slate-300 hover:!bg-slate-500 text-black hover:!text-white`, [classNames])
    return <Paper className={current ? currentClassNames : defaultClassNames} onClick={handleRoomClick}>
        <FlexCol justifyContent='space-between' className="h-[100px] p-1">
            <FlexRow justifyContent='space-between' className="w-[100%] px-2 grow">
                <p className="text-xl text-left">{room.name}</p>
                <p className="text-xl text-right ">{room.numberOfPeople} posti</p>
            </FlexRow>
            <FlexRow justifyContent='space-between' className="w-[100%] px-2 grow">
                <p className="text-xl text-left">{room.floorNumber && `${room.floorNumber}° piano`}</p>
            </FlexRow>
            <FlexRow justifyContent='space-between' className="w-[100%] px-2 grow">
                <p className="text-xl text-start grow">{room.description}</p>
            </FlexRow>
        </FlexCol>
    </Paper>
}
export { RoomCard }