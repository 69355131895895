import React from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { FlexCol } from '../../components/Flex/FlexCol';
import { Spacer } from '../../components/Spacer/Spacer';
import { useNavigate } from 'react-router';
import Button from '@mui/material/Button';
import { registerRoomBook } from '../../services/rooms';
import { useGlobalContext } from '../../stores/hooks/useGlobalContext';
import { UrlsEnum } from '../../types/urls';
import { useQueryValues } from '../../hooks/useQueryValues';
import { useErrorsContext } from '../../stores/hooks/useErrorsContext';
import { SectionSeparator } from '../../components/SectionSeparator/SectionSeparator';
import { RoomSelector } from '../../components/SelectorContainer/RoomSelector/RoomSelector';
import { BuildingSelector } from '../../components/SelectorContainer/BuildingSelector/BuildingSelector';
import { AxiosError } from 'axios';


const IndexPage: React.FC = () => {
    const navigate = useNavigate();
    const {
        queryValues,
        encodeValues,
    } = useQueryValues();
    const {
        buildingsError, noBuildingSelectedError, roomNotFoundError,
    } = useErrorsContext();
    const { accounts } = useMsal();
    const isAuth = useIsAuthenticated();
    const {
        globalLoading,
        globalBuildings,
        setLoading,
    } = useGlobalContext();

    const handleBookClick = React.useCallback(async () => {
        setLoading(true);
        registerRoomBook(queryValues)
            .then((res: any) => {
                navigate(encodeValues(UrlsEnum.BOOK_CONFIRM, undefined, queryValues))
            })
            .catch((err: AxiosError) => {
                if (err.response && err.response.status !== 401) {
                    alert("Prenotazione non riuscita. Contattare l'amministratore")
                }
            })
            .finally(() => setLoading(false))
    }, [queryValues])
    const handleStatsClick = () => {
        if (process.env.REACT_APP_STATS_FILE_URL) {
            window.open(process.env.REACT_APP_STATS_FILE_URL)
        }
    }
    const name1 = React.useMemo(() => accounts[0] && accounts[0].name ? accounts[0].name.split('(')[0] : 'Anonimo', [accounts])
    const name2 = React.useMemo(() => {
        if (accounts[0] && accounts[0].name) {
            return accounts[0].name.indexOf('(') > 0 ? accounts[0].name.slice(accounts[0].name.indexOf('(')) : ''
        }
    }, [accounts])
    const disabledActions = React.useMemo(() => {
        return !queryValues.name || globalLoading || !isAuth
            || buildingsError !== '' || noBuildingSelectedError !== '' || roomNotFoundError !== ''
    }, [buildingsError, noBuildingSelectedError, roomNotFoundError, queryValues, isAuth, globalLoading])
    return <>
        <FlexCol justifyContent='start' alignItems='center' className="p-1" style={{ maxHeight: '100%', overflow: 'auto' }}>
            <p className="text-xl text-center">Benvenuto</p>
            <p className="text-xl w-[100%] font-bold text-center">{name1}</p>
            {name2 && <p className="text-base w-[100%] text-center">{name2}</p>}
            <SectionSeparator />
            <FlexCol justifyContent='start' alignItems='center' className="w-[100%]">
                <div className="mt-1" />
                <BuildingSelector />
                <div className="mb-1" />
                <RoomSelector />
                <div className="mb-1" />
            </FlexCol>
            <SectionSeparator />
            {queryValues.description && queryValues.floorNumber &&
                <>
                    <FlexCol justifyContent='start' alignItems='center' className="w-[100%] border-b">
                        <p className="text-base text-center" style={{ margin: 2 }}>{queryValues.description}</p>
                        <p className="text-base text-center" style={{ margin: 2 }}>Piano {queryValues.floorNumber}</p>
                    </FlexCol>
                    <SectionSeparator />
                </>
            }
            <Spacer direction='up' size="xl" />
            <div style={{ marginTop: 30 }} />
            <Button disabled={disabledActions} style={{ minWidth: 250, width: '80%', minHeight: 50 }} variant="contained" color="primary" onClick={handleBookClick}>
                <p className="text-lg text-center">Prenota Sala</p>
            </Button>
            <Spacer direction='up' size="m" />
            <Button disabled={disabledActions} style={{ minWidth: 250, width: '80%', minHeight: 50 }} variant="contained" color="primary" onClick={handleStatsClick}>
                <p className="text-lg text-center">Statistiche Sala</p>
            </Button>
            {/* <Spacer direction='up' size="m" />
            <Button disabled={!queryValues.name || globalState.ISLOADING} style={{ width: '80%', minHeight: 50 }} variant="contained" color="primary" onClick={handleRoomsClick}>
                <p className="text-lg text-center">Sale con stessa capacità</p>
            </Button> */}
        </FlexCol>
    </>
}
export { IndexPage }