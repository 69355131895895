import React from 'react';
import { FormControl, InputLabel, MenuItem, Modal, Select, SelectChangeEvent } from '@mui/material';
import { roomsMockData } from '../../pages/RoomsList/mockedData';
import { useNavigate } from 'react-router';
import { useQueryValues } from '../../hooks/useQueryValues';
import { useStateIsOpen } from '../../utility/react-utilities';
import { IRoom } from '../../types/rooms';
import { useNavigationContext } from '../../stores/hooks/useNavigationContext';
import { RoomsList } from '../../pages/RoomsList/RoomsList';
type Props = {
    rooms: IRoom[]
}
const NumberOfPartecipantsSelector: React.FC<Props> = ({ rooms }) => {
    const {
        queryValues,
        replaceEncodedValue
    } = useQueryValues();
    // const navigate = useNavigate();
    const {
        isOpen,
        setIsOpen,
        handleOpen,
    } = useStateIsOpen(rooms.length > 0 && !(parseInt(queryValues.numberOfPeople) > 0));
    const {
        navigationType,
        numberOfPartecipants: currentNumberOfPartecipants,
        setNumberOfPartecipants,
        setPanelChildrens,
    } = useNavigationContext();
    const classNames = React.useMemo(() => 'text-xl px-4 py-1 rounded border cursor-pointer', [])
    const selectedClassNames = React.useMemo(() => `${classNames} mt-2 text-white border-white bg-main-green`, [classNames])
    const deselectedClassNames = React.useMemo(() => `${classNames} mt-2 bg-white text-black border-white hover:bg-main-green`, [classNames])
    const currentClassNames = React.useMemo(() => `${classNames} text-white bg-main-green border-main-dark-green`, [classNames])
    const availableNumberOfPartecipants = React.useMemo(() => {
        let result: number[] = []
        roomsMockData.filter((r) => r.cri === queryValues.cri).map((x) => parseInt(x.numberOfPeople)).sort((a, b) => a > b ? -1 : 1).forEach((n) => {
            if (!result.includes(n)) {
                result.push(n)
            }
        })
        return result;
    }, [roomsMockData])
    const handleClose = () => {
        if (navigationType === "panel") {
            setPanelChildrens(<RoomsList />)
        } else {
            setIsOpen(false);
        }
    }
    const handleSelect = (x: string) => {
        // let url = window.location.pathname;
        // if (queryValues.numberOfPeople !== x) {
        //     let firstRoom = roomsMockData.filter((room)=>room.numberOfPeople === x)[0]
        //     if(firstRoom){
        //         url = replaceEncodedValue(['name','code','description','floorNumber','numberOfPeople'],[firstRoom.name,firstRoom.code,firstRoom.description,firstRoom.floorNumber,x])
        //         navigate(url);
        //     }
        // }
        // navigate(replaceEncodedValue(['numberOfPeople'],[x]))
        setNumberOfPartecipants(parseInt(x))
        handleClose();
    }
    const selector = <div className="flex flex-col items-center justify-center w-[200px] bg-main-dark-green rounded-xl p-2 py-4 overflow-auto max-h-[500px] border border-white">
        {availableNumberOfPartecipants.map((x) => (
            x.toString() === queryValues.numberOfPeople ?
                <p key={'selector-' + x} className={selectedClassNames} onClick={() => handleSelect(x.toString())}>{x} posti</p>
                :
                <p key={'selector-' + x} className={deselectedClassNames} onClick={() => handleSelect(x.toString())}>{x} posti</p>

        ))}
    </div>
    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        if (navigationType === "default") {
            handleOpen();
        } else {
            setPanelChildrens(selector);
        }
    }
    const handleSelectChange = (e:SelectChangeEvent)=>{
        setNumberOfPartecipants(parseInt(e.target.value));
    }
    if (navigationType === "default") {
        return <>
            <p className={currentClassNames} onClick={rooms.length > 0 ? handleClick : undefined}>{currentNumberOfPartecipants} posti</p>
            <Modal open={isOpen as boolean} onClose={handleClose} className='flex flex-col items-center justify-center'>
                {selector}
            </Modal>
        </>
    }
    if (navigationType === "panel") {
        return <FormControl>
            <InputLabel id="select-label">Posti</InputLabel>
            <Select
                labelId="select-label"
                id="simple-select"
                value={currentNumberOfPartecipants}
                label="Posti"
                onChange={handleSelectChange}
            >
                {availableNumberOfPartecipants.map((v)=><MenuItem key={v.toString()} value={v}>{v}</MenuItem>)}
            </Select>
        </FormControl>
    }
    return null;
}
export { NumberOfPartecipantsSelector }