import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Button, CircularProgress, IconButton, Modal, Paper } from '@mui/material';
import React from 'react';
import { FiLogIn, FiLogOut } from 'react-icons/fi';
import { FlexRow } from '../Flex/FlexRow';
import { useGlobalContext } from '../../stores/hooks/useGlobalContext';
import { apiLoginRequest } from '../../authConfig';
import { isMobile } from 'react-device-detect';
import { useStateIsOpen } from '../../utility/react-utilities';
type Props = {
    buttonStyles?: React.CSSProperties
}
const AuthButton: React.FC<Props> = ({ buttonStyles }) => {
    const {
        instance
    } = useMsal();
    const {
        globalLoading
    } = useGlobalContext();
    const isAuthenticated = useIsAuthenticated();
    const {
        isOpen,
        setIsOpen,
        handleOpen,
        handleClose,
    } = useStateIsOpen();
    const handleOpenConfirmModal = () => {
        handleOpen()
    }
    const handleLogout = () => {
        instance.logout();
    }
    const handleLogin = () => {
        instance.loginRedirect(apiLoginRequest);
    }
    const top = React.useMemo(()=>{
        if(isMobile){
            return '5%';
        }
        return '35%'
    },[isMobile])
    if (globalLoading) {
        return <CircularProgress style={buttonStyles} />
    }
    return isAuthenticated ?
        <>
            < IconButton style={buttonStyles} onClick={handleOpenConfirmModal} className="!hover:bg-transparent !bg-transparent text-black text-base !ml-4" >
                <FiLogOut />
            </IconButton >
            <Modal
                open={isOpen as boolean}
                onClose={handleClose}
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Paper className="flex flex-col justify-between p-2" style={{ width: 300, height: 100,position:'fixed',top, }}>
                    <p className="w-[100%] text-xl text-center">Vuoi disconnetterti?</p>
                    <FlexRow justifyContent='space-between' className="w-[80%] mx-auto mt-auto mb-2 self-end">
                        <Button onClick={handleClose} variant="outlined" color="secondary">Annulla</Button>
                        <Button onClick={handleLogout} variant="contained" color="secondary">Logout</Button>
                    </FlexRow>
                </Paper>
            </Modal>
        </>
        : < IconButton style={buttonStyles} onClick={handleLogin} className="!hover:bg-transparent !bg-transparent text-black text-base !ml-4">
            <FiLogIn />
        </IconButton>;
}
export { AuthButton }