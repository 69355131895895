import React from 'react';
import { FlexRow } from '../Flex/FlexRow';
import { topbarHeight } from '../Topbar/Topbar';
import './styles.css'
import { isMobile } from 'react-device-detect';
const Bottombar: React.FC = () => {
    const imgStyle:React.CSSProperties= React.useMemo(()=>({
        objectFit:'contain',
        width:200,
        height:'100%'
    }),[])
    return <FlexRow alignItems='center' justifyContent='center' className="bottombar" style={{height:topbarHeight,borderRadius:isMobile ? 0 :'0px 0px 10px 10px'}}>
        <img style={imgStyle} src={require(`../../images/logo/IntesaLogoWhite.png`)} alt="Image Missing" />
    </FlexRow>
}
export { Bottombar }