export enum StoreKey {
    USER = "USER",
    GLOBAL = "GLOBAL",
    NAVIGATION = "NAVIGATION"
}
export function getInitialState(key: StoreKey) {
    const storedJson = localStorage.getItem(key)
    if (storedJson) {
        return JSON.parse(storedJson);
    }
    else {
        return null;
    }
}

export function saveState(key:StoreKey,value:any){
    localStorage.setItem(key,JSON.stringify(value))
}