const clientId = process.env.REACT_APP_MSAL_CLIENTID ?? "";
const tenantId = process.env.REACT_APP_MSAL_TENANTID ?? "";
const authority = `https://login.microsoftonline.com/${tenantId}`;
export const msalConfig = {
    auth: {
        clientId,
        authority,
        redirectUri: process.env.REACT_APP_MSAL_REDIRECTURI,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};
export const apiLoginRequest = {
    scopes: [process.env.REACT_APP_MSAL_REQUIRED_SCOPES!]
};