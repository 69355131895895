import React from 'react';
import { FlexCol } from '../../components/Flex/FlexCol';
import { useGlobalContext } from '../../stores/hooks/useGlobalContext';
import { GoVerified } from 'react-icons/go';
import { useQueryValues } from '../../hooks/useQueryValues';
const BookConfirm: React.FC = () => {
    const {
        queryValues
    } = useQueryValues();
    const {
        globalBuildings
    } = useGlobalContext();
    return <FlexCol justifyContent='center'alignItems='center'>
            <GoVerified className="text-main-green font-bold text-4xl" style={{marginTop:60}}/>
            {/* <p className="text-4xl text-center text-main-green" >Prenotazione Confermata</p> */}
            <div className="mt-4"/>
            {globalBuildings[queryValues.cri] && <p className="text-3xl text-center">{globalBuildings[queryValues.cri].BuildingName}</p>}
            <p className="text-2xl text-center">{queryValues.name} - {queryValues.floorNumber}° piano</p>
            <p className="text-xl text-center">{queryValues.numberOfPeople} posti</p>
        </FlexCol>
}
export { BookConfirm }