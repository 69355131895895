import { CircularProgress } from '@mui/material';
import React from 'react';
const SelectorLoadingIcon: React.FC = () => {
    const circleDimension = React.useMemo(() =>20,[]);
    return <>
        <div className="mx-1" />
        <div style={{ margin: '2px 0px', height: circleDimension, width: circleDimension }}>
            <CircularProgress style={{ width: circleDimension, height: circleDimension }} />
        </div>
    </>
}
export { SelectorLoadingIcon }