import './App.css';
import { Route, Routes } from 'react-router';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { UnauthenticatedPage } from './pages/unauthenticated/UnauthenticatedPage';
import { IndexPage } from './pages/Index/Index';
import { UserProvider } from './stores/userStore';
import { BookConfirm } from './pages/BookConfirm/BookConfirm';
import { MobileWrapper } from './components/MobileWrapper/MobileWrapper';
import { RoomsList } from './pages/RoomsList/RoomsList';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './themes/theme';
import { GlobalProvider } from './stores/globalStore';
import { UrlsEnum } from './types/urls';
import { BuildingsList } from './pages/BuildingsList/BuildingsList';
import { ErrorsProvider } from './stores/errorsStore';
import { NavigationProvider } from './stores/navigationStore';
import { PowerBIWrapper } from './components/PowerBI/PowerBIWrapper';

const App: React.FC = () => {
  return (
    <ErrorsProvider>
      <GlobalProvider>
        <NavigationProvider>
          <UserProvider>
            <ThemeProvider theme={theme}>
              <MobileWrapper>
                <AuthenticatedTemplate>
                  <Routes>
                    <Route path={UrlsEnum.INDEX} element={<IndexPage />} />
                    <Route path={UrlsEnum.BOOK_CONFIRM} element={<BookConfirm />} />
                    <Route path={UrlsEnum.ROOMS_LIST} element={<RoomsList />} />
                    <Route path={UrlsEnum.BUILDINGS_LIST} element={<BuildingsList />} />
                    <Route path={UrlsEnum.POWER_BI} element={<PowerBIWrapper />} />
                  </Routes>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <UnauthenticatedPage />
                </UnauthenticatedTemplate>
              </MobileWrapper>
            </ThemeProvider>
          </UserProvider>
        </NavigationProvider>
      </GlobalProvider>
    </ErrorsProvider>
  );
}

export default App;
