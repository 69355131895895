export const roomsMockData = [
    {
        "cri": "IT015146000829",
        "floorNumber": "22",
        "description": "Sala VIP1 (24 postazioni est)",
        "numberOfPeople": "24",
        "code": "1",
        "name": "Azalea",
        "fullName": "Sala Consiglio 22.1. Azalea"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "22",
        "description": "Sala VIP2 (24 postazioni ovest)",
        "numberOfPeople": "24",
        "code": "2",
        "name": "Rododendro",
        "fullName": "Sala Consiglio 22.2. Rododendro"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "22",
        "description": "Sala VIP3 (16 postazioni est)",
        "numberOfPeople": "16",
        "code": "3",
        "name": "Magnolia",
        "fullName": "Sala 22.3. Magnolia"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "22",
        "description": "Sala VIP4 (16 postazioni ovest)",
        "numberOfPeople": "16",
        "code": "4",
        "name": "Ciclamino",
        "fullName": "Sala 22.4. Ciclamino"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "22",
        "description": "Sala VIP5 (8 postazioni nord)",
        "numberOfPeople": "8",
        "code": "5",
        "name": "Ginepro",
        "fullName": "Sala 22.5. Ginepro"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "22",
        "description": "Sala VIP6 (8 postazioni sud)",
        "numberOfPeople": "8",
        "code": "6",
        "name": "Mimosa",
        "fullName": "Sala 22.6. Mimosa"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "22",
        "description": "Sala Riunioni Fideuram",
        "numberOfPeople": "8",
        "code": "7",
        "name": "Lavanda",
        "fullName": "Sala 22.7. Lavanda"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "22",
        "description": "Sala Riunioni ISPB",
        "numberOfPeople": "8",
        "code": "8",
        "name": "Inga",
        "fullName": "Sala 22.8. Inga"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "22",
        "description": "Sala Riunioni Isybank",
        "numberOfPeople": "8",
        "code": "9",
        "name": "Mirto",
        "fullName": "Sala 22.9. Mirto"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "21",
        "description": "Sala Riunioni (Large)",
        "numberOfPeople": "12",
        "code": "1",
        "name": "Noce",
        "fullName": "Sala 21.1. Noce"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "21",
        "description": "Sala Riunioni (Medium)",
        "numberOfPeople": "8",
        "code": "2",
        "name": "Edera",
        "fullName": "Sala 21.2. Edera"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "20",
        "description": "Sala Riunioni (Large)",
        "numberOfPeople": "12",
        "code": "1",
        "name": "Sambuco",
        "fullName": "Sala 20.1. Sambuco"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "20",
        "description": "Sala Riunioni (Medium)",
        "numberOfPeople": "8",
        "code": "2",
        "name": "Melograno",
        "fullName": "Sala 20.2. Melograno"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "19",
        "description": "Sala Riunioni (Medium)",
        "numberOfPeople": "8",
        "code": "1",
        "name": "Fiordaliso",
        "fullName": "Sala 19.1. Fiordaliso"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "19",
        "description": "Sala Riunioni (Small) nord",
        "numberOfPeople": "6",
        "code": "2",
        "name": "Limone",
        "fullName": "Sala 19.2. Limone"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "19",
        "description": "Sala Riunioni (Small) sud",
        "numberOfPeople": "6",
        "code": "3",
        "name": "Narciso",
        "fullName": "Sala 19.3. Narciso"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "18",
        "description": "Sala Riunioni (Medium)",
        "numberOfPeople": "8",
        "code": "1",
        "name": "Agrifoglio",
        "fullName": "Sala 18.1. Agrifoglio"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "18",
        "description": "Sala Riunioni (Small)",
        "numberOfPeople": "6",
        "code": "2",
        "name": "Melo",
        "fullName": "Sala 18.2. Melo"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "17",
        "description": "Sala Riunioni (Large)",
        "numberOfPeople": "12",
        "code": "1",
        "name": "Aloe",
        "fullName": "Sala 17.1. Aloe"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "17",
        "description": "Sala Riunioni (Medium)",
        "numberOfPeople": "8",
        "code": "2",
        "name": "Cipresso",
        "fullName": "Sala 17.2. Cipresso"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "16",
        "description": "Sala Riunioni (Large)",
        "numberOfPeople": "12",
        "code": "1",
        "name": "Iris",
        "fullName": "Sala 16.1. Iris"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "16",
        "description": "Sala Riunioni (Medium)",
        "numberOfPeople": "8",
        "code": "2",
        "name": "Felce",
        "fullName": "Sala 16.2. Felce"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "15",
        "description": "Sala Riunioni (Large)",
        "numberOfPeople": "12",
        "code": "1",
        "name": "Faggio",
        "fullName": "Sala 15.1. Faggio"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "15",
        "description": "Sala Riunioni (Medium)",
        "numberOfPeople": "8",
        "code": "2",
        "name": "Rosa",
        "fullName": "Sala 15.2. Rosa"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "14",
        "description": "Sala Riunioni (Large)",
        "numberOfPeople": "12",
        "code": "1",
        "name": "Acero",
        "fullName": "Sala 14.1. Acero"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "14",
        "description": "Sala Riunioni (Medium)",
        "numberOfPeople": "8",
        "code": "2",
        "name": "Calla",
        "fullName": "Sala 14.2. Calla"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "14",
        "description": "Sala Riunioni (Small)",
        "numberOfPeople": "6",
        "code": "3",
        "name": "Eucalipto",
        "fullName": "Sala 14.3. Eucalipto"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "13",
        "description": "Sala Riunioni (Large)",
        "numberOfPeople": "12",
        "code": "1",
        "name": "Tulipano",
        "fullName": "Sala 13.1. Tulipano"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "13",
        "description": "Sala Riunioni (Medium)",
        "numberOfPeople": "8",
        "code": "2",
        "name": "Ciliegio",
        "fullName": "Sala 13.2. Ciliegio"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "12",
        "description": "Sala Riunioni (Large)",
        "numberOfPeople": "12",
        "code": "1",
        "name": "Camelia",
        "fullName": "Sala 12.1. Camelia"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "12",
        "description": "Sala Riunioni (Medium)",
        "numberOfPeople": "8",
        "code": "2",
        "name": "Vite",
        "fullName": "Sala 12.2. Vite"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "12",
        "description": "Sala Riunioni (Small) nord",
        "numberOfPeople": "6",
        "code": "3",
        "name": "Geranio",
        "fullName": "Sala 12.3. Geranio"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "12",
        "description": "Sala Riunioni (Small) sud",
        "numberOfPeople": "6",
        "code": "4",
        "name": "Pino",
        "fullName": "Sala 12.4. Pino"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "11",
        "description": "Sala Riunioni (Large)",
        "numberOfPeople": "12",
        "code": "1",
        "name": "Papavero",
        "fullName": "Sala 11.1. Papavero"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "11",
        "description": "Sala Riunioni (Medium)",
        "numberOfPeople": "8",
        "code": "2",
        "name": "Cedro",
        "fullName": "Sala 11.2. Cedro"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "11",
        "description": "Sala Riunioni (Small)",
        "numberOfPeople": "6",
        "code": "3",
        "name": "Olivo",
        "fullName": "Sala 11.3. Olivo"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "10",
        "description": "Sala Riunioni (Large)",
        "numberOfPeople": "12",
        "code": "1",
        "name": "Peonia",
        "fullName": "Sala 10.1. Peonia"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "10",
        "description": "Sala Riunioni (Medium)",
        "numberOfPeople": "8",
        "code": "2",
        "name": "Giglio",
        "fullName": "Sala 10.2. Giglio"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "10",
        "description": "Sala Riunioni (Small)",
        "numberOfPeople": "6",
        "code": "3",
        "name": "Gelsomino",
        "fullName": "Sala 10.3. Gelsomino"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "9",
        "description": "Sala Riunioni (Large)",
        "numberOfPeople": "12",
        "code": "1",
        "name": "Palma",
        "fullName": "Sala 9.1. Palma"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "9",
        "description": "Sala Riunioni (Medium)",
        "numberOfPeople": "8",
        "code": "2",
        "name": "Ibisco",
        "fullName": "Sala 9.2. Ibisco"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "8",
        "description": "Sala Riunioni (Large)",
        "numberOfPeople": "12",
        "code": "1",
        "name": "Ortensia",
        "fullName": "Sala 8.1. Ortensia"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "8",
        "description": "Sala Riunioni (Medium)",
        "numberOfPeople": "8",
        "code": "2",
        "name": "Lill\u00ef\u00bf\u00bd",
        "fullName": "Sala 8.2. Lill\u00ef\u00bf\u00bd"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "7",
        "description": "Sala Riunioni (Large)",
        "numberOfPeople": "12",
        "code": "1",
        "name": "Primula",
        "fullName": "Sala 7.1. Primula"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "7",
        "description": "Sala Riunioni (Medium)",
        "numberOfPeople": "8",
        "code": "2",
        "name": "Pero",
        "fullName": "Sala 7.2. Pero"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "6",
        "description": "Sala Riunioni (Large)",
        "numberOfPeople": "12",
        "code": "1",
        "name": "Giunco",
        "fullName": "Sala 6.1. Giunco"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "6",
        "description": "Sala Riunioni (Medium)",
        "numberOfPeople": "8",
        "code": "2",
        "name": "Nocciolo",
        "fullName": "Sala 6.2. Nocciolo"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "5",
        "description": "Sala Riunioni (Large)",
        "numberOfPeople": "12",
        "code": "1",
        "name": "Mandorlo",
        "fullName": "Sala 5.1. Mandorlo"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "5",
        "description": "Sala Riunioni (Medium)",
        "numberOfPeople": "8",
        "code": "2",
        "name": "Glicine",
        "fullName": "Sala 5.2. Glicine"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "5",
        "description": "Sala Riunioni (Small) nord",
        "numberOfPeople": "6",
        "code": "3",
        "name": "Dalia",
        "fullName": "Sala 5.3. Dalia"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "5",
        "description": "Sala Riunioni (Small) sud",
        "numberOfPeople": "6",
        "code": "4",
        "name": "Loto",
        "fullName": "Sala 5.4. Loto"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "4",
        "description": "Sala Riunioni (Large)",
        "numberOfPeople": "12",
        "code": "1",
        "name": "Viola",
        "fullName": "Sala 4.1. Viola"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "4",
        "description": "Sala Riunioni (Medium)",
        "numberOfPeople": "8",
        "code": "2",
        "name": "Betulla",
        "fullName": "Sala 4.2. Betulla"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "3",
        "description": "Sala Riunioni (Large)",
        "numberOfPeople": "12",
        "code": "1",
        "name": "Quercia",
        "fullName": "Sala 3.1. Quercia"
    },
    {
        "cri": "IT015146000829",
        "floorNumber": "3",
        "description": "Sala Riunioni (Medium)",
        "numberOfPeople": "8",
        "code": "2",
        "name": "Salice",
        "fullName": "Sala 3.2. Salice"
    },
    {
        "cri": "IT001156000001",
        "floorNumber": "3",
        "description": "Test Sala Moncalieri",
        "numberOfPeople": "8",
        "code": "2",
        "name": "Test-Sala Moncalieri",
        "fullName": "FULL Test-Sala Moncalieri"
    }
]