import React from "react";
import { useQuery } from "../hooks/useQuery";
import { IRoom } from "../types/rooms";
export const CODICE_IMMOBILE_QUERY_KEY = 'cri';
export const PIANO_QUERY_KEY = 'p';
export const DESCRIZIONE_QUERY_KEY = 'd';
export const NUM_POSTAZIONI_QUERY_KEY = 'np';
export const COD_QUERY_KEY = 'cod';
export const NOME_QUERY_KEY = 'n';
export const NOME_COMPLETO_QUERY_KEY = 'nc';
export function useQueryValues() {
    const query = useQuery();
    const queryKeysMap = {
        cri: CODICE_IMMOBILE_QUERY_KEY,
        floorNumber: PIANO_QUERY_KEY,
        description: DESCRIZIONE_QUERY_KEY,
        numberOfPeople: NUM_POSTAZIONI_QUERY_KEY,
        code: COD_QUERY_KEY,
        name: NOME_QUERY_KEY,
        fullName: NOME_COMPLETO_QUERY_KEY,
    }
    const queryValues: IRoom = React.useMemo(() => ({
        cri: query.get(queryKeysMap.cri) ?? '',
        floorNumber: query.get(queryKeysMap.floorNumber) ?? '',
        description: query.get(queryKeysMap.description) ?? '',
        numberOfPeople: query.get(queryKeysMap.numberOfPeople) ?? '',
        code: query.get(queryKeysMap.code) ?? '',
        name: query.get(queryKeysMap.name) ?? '',
        fullName: query.get(queryKeysMap.fullName) ?? '',
    }), [query, queryKeysMap])
    const encodeValues = (url: string, keys?: (keyof IRoom)[], values?: IRoom, onlyNewValues?:boolean) => {
        let result: string = '';
        let keysToEncode: (keyof IRoom)[] = keys ? keys : Object.keys(queryValues) as (keyof IRoom)[]
        keysToEncode.forEach((k, index) => {
            let value = values && values[k] ? values[k] : queryValues[k];
            if(onlyNewValues && values){
                value = values[k]
            }
            if (value) {
                if (!result) {
                    result = url.endsWith('?') ? url : `${url}?`
                }
                result += `${queryKeysMap[k]}=${value}${index < keysToEncode.length - 1 ? '&' : ''}`
            }
        });
        if(!result){
            return url;
        }
        return result.endsWith('&') ? result.slice(0, -1) : result;
    }
    const replaceEncodedValue = (keys: (keyof IRoom)[], values: string[]) => {
        let used_keys:(keyof IRoom)[] = [];
        let used_values:string[] = []
        Object.entries(queryValues).forEach(([k,v])=>{
            const needed_key = keys.includes(k as keyof IRoom);
            if(v || needed_key){
                used_keys.push(k as keyof IRoom)
                used_values.push(needed_key ? values[keys.indexOf(k as keyof IRoom)] : v)
            }
        })
        return encodeValues(window.location.pathname,used_keys,Object.fromEntries(used_values.map((x,i)=>[used_keys[i],x])) as any)
    }
    return {
        query,
        queryValues,
        encodeValues,
        replaceEncodedValue,
    }
}