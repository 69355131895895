import React from "react";
import { GLOBAL_SET_ACTION, GlobalStateKeysEnum, globalContext } from "../globalStore";
import { TBuildingMap } from "../../types/building";
import { useTypedContext } from "./useContext";
import { StoreKey, saveState } from "../autosave";
const STORE_KEY = StoreKey.GLOBAL;
export const useGlobalContext = () => {
    const {
        state: globalState,
        dispatch: globalDispatch,
        setActions
    } = useTypedContext({actionType:GLOBAL_SET_ACTION,context:globalContext,keys_enum:GlobalStateKeysEnum})
    const setBuildings = React.useCallback((payload: TBuildingMap) => setActions[GlobalStateKeysEnum.BUILDINGS](payload),[setActions,GlobalStateKeysEnum])
    const setLoading = React.useCallback((payload: boolean) => setActions[GlobalStateKeysEnum.ISLOADING](payload),[setActions,GlobalStateKeysEnum])
    
    return {
        globalState,
        globalLoading:globalState.ISLOADING,
        globalBuildings:globalState.BUILDINGS,
        globalDispatch,
        setBuildings,
        setLoading
    }
}