import React from "react";
import { useLocation } from "react-router-dom";
import { useGlobalContext } from "../stores/hooks/useGlobalContext";
import { useQueryValues } from "./useQueryValues";

function useTitle() {
    const setPortalTitle = React.useCallback((s: string) => {
        document.title = s;
    }, [])
    const {
        queryValues
    } = useQueryValues();
    const location = useLocation();
    const {
        globalBuildings
    } = useGlobalContext();
    React.useEffect(() => {
        const roomName = queryValues.name;
        const numberOfPeople = queryValues.numberOfPeople;
        if (globalBuildings[queryValues.cri]) {
            setPortalTitle(`SB Rooms - ${globalBuildings[queryValues.cri].BuildingName} ${roomName} (${numberOfPeople} posti)`)
        }
    }, [location.pathname, queryValues])
}
export { useTitle }