import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { useEffect } from 'react';
import { apiLoginRequest } from '../../authConfig';


export const loginTentativesKey = "loginTentatives";
export const UnauthenticatedPage = () => {

  const { instance, inProgress } = useMsal();
  const authenticated = useIsAuthenticated();
  useEffect(() => {
    if (!authenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect(apiLoginRequest)
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {

        })
    }
  }, [])


  return <div style={{ display: "flex", justifyContent: "center", verticalAlign: "center" }}>
    <p>You are not signed in! Please sign in.</p>
  </div>
}