import React from "react";
import { GlobalStateKeysEnum } from "../globalStore"
import { SetActionType, useSetActions } from "./useSetActions";
import { UsereStateKeysEnum } from "../userStore";
import { ErrorsStateKeysEnum } from "../errorsStore";
import { NavigationStateKeysEnum } from "../navigationStore";

export type StateEnum = (typeof GlobalStateKeysEnum) | (typeof ErrorsStateKeysEnum) | (typeof NavigationStateKeysEnum);
export type TState = {[key in keyof StateEnum]:any}
export interface UseContextProps {
    actionType:SetActionType,
    keys_enum:StateEnum,
    context:React.Context<any>,
}

export const useTypedContext = ({actionType,context,keys_enum} : UseContextProps) =>{
    const { state, dispatch } = React.useContext(context);
    const {
        setActions
    } = useSetActions(actionType,state,dispatch);
    return {
        state,dispatch,setActions
    }
}