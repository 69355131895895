import React from 'react';
import '../../utility/commonStyles.css';
import { FlexCol } from '../Flex/FlexCol';
import { useLocation, useNavigate } from 'react-router';
import { FaArrowLeft } from "react-icons/fa";
import { isMobile } from 'react-device-detect';
import { AuthButton } from '../AuthButton/AuthButton';
import { routesGoBackMap } from '../../types/urls';
import { useQueryValues } from '../../hooks/useQueryValues';


export const topbarHeight = 65;

const Topbar: React.FC = () => {
    const {
        encodeValues
    } = useQueryValues();
    const navigate = useNavigate();
    const location = useLocation();
    const [showBackArrow, setShowBackArrow] = React.useState(location.pathname !== '/')
    React.useEffect(() => {
        setShowBackArrow(location.pathname !== '/')
    }, [location.pathname])
    const goBack = () => {
        navigate(encodeValues(routesGoBackMap[location.pathname]) ?? -1)
    }
    return <FlexCol className="topbar" style={{ height: topbarHeight, borderRadius: isMobile ? 0 : '10px 10px 0px 0px' }}>
        {showBackArrow && <FaArrowLeft style={{ position: 'absolute', left: 10, top: 10, color: 'white', zIndex: 99999, cursor: 'pointer', fontSize: 20 }} onClick={goBack} />}
        <p className="text-2xl text-center" style={{ width: '100%', margin: '3px 0px 1px 0px' }}>Smart Building Project</p>
        <p className="text-lg text-center" style={{ width: '100%', margin: '1px 0px 2px 0px' }}>Demo prenotazione sale</p>
        <AuthButton buttonStyles={{ position: 'absolute', top: 10, right: 10, color: 'white' }} />
    </FlexCol>
}
export { Topbar }