import React from "react"

export const useStateIsOpen = (startValue?: boolean) => {
    const [isOpen, setIsOpen] = React.useState(startValue === true);
    const handleOpen = React.useCallback(() => {
        setIsOpen(true)
    }, [setIsOpen])
    const handleClose = React.useCallback(() => {
        setIsOpen(false)
    }, [setIsOpen])
    return {
        isOpen,
        setIsOpen,
        handleOpen,
        handleClose
    }
}

export const useToggle = (startValue?: boolean) => {
    const [value, setValue] = React.useState(startValue as boolean);
    const handleToggle = React.useCallback(() => {
        setValue(!value)
    }, [value])
    return {
        value,
        setValue,
        handleToggle
    }
}