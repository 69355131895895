import React from 'react';
import { TSize } from '../../types/general';
type Props = {
    size: TSize
    direction: "up" | "down" | "left" | "right"
}
const Spacer: React.FC<Props> = ({ size, direction }) => {
    const _direction = React.useMemo(() => {
        switch (direction) {
            case "up": return [1, 0, 0, 0]
            case "down": return [0, 0, 1, 0]
            case "left": return [0, 0, 0, 1]
            case "right": return [0, 1, 0, 0]
            default: return [1, 0, 0, 0]
        }
    }, [direction])
    const margin = React.useMemo(() => {
        let _size = 2;
        switch (size) {
            case "s": { break; }
            case "m": {
                _size *= 2;
                break;
            }
            case "l": {
                _size *= 4;
                break;
            }
            case "xl": {
                _size *= 6;
                break;
            }
            case "xxl": {
                _size *= 8;
                break;
            }
            case "xxxl": {
                _size *= 10;
                break;
            } 
            default:break;
        }
        return _direction.map(x => `${x * _size}px`).join(' ')
    }, [size])
    return <div style={{ margin }} />
}
export { Spacer }