import React from 'react';
import { useGlobalContext } from '../../stores/hooks/useGlobalContext';
type Props = {
    onClick: (e?: React.MouseEvent<HTMLElement>) => void,
    children: React.ReactNode
}

const SelectorContainer: React.FC<Props> = ({ children, onClick }) => {
    const { globalLoading } = useGlobalContext();
    const classNames = React.useMemo(() =>
        "min-h-[35px] transition-all-short px-2 flex flex-row w-[100%] min-w-[300px] items-center cursor-pointer hover:!bg-slate-300 text-main-green border border-main-green rounded"
        , [])
    return <div
        onClick={globalLoading ? undefined : onClick}
        className={classNames}>
        {children}
    </div>
}
export { SelectorContainer }